import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const renderItems = edges => (
  <div className="tech-box-container">
    {edges.map((edge, index) => (
      <Link to={`/${edge.node.id}`} key={edge.node.id}>
        <div
          className={`tech-box tech-box-md-${(index + 1) %
            2} tech-box-lg-${(index + 1) % 3}`}
        >
          <p>{edge.node.name}</p>
        </div>
      </Link>
    ))}
  </div>
)

export default ({ pageContext, data }) => {
  const { tag } = pageContext

  return (
    <Layout>
      <SEO title={`Tag: ${tag}`} />
      <div className="container full no-margin">
        <div className="container textCenter">
          <h2>
            Tag: '<span>{tag}</span>'
          </h2>
        </div>
        <div className="container no-padding">
          {renderItems(data.allListJson.edges)}
          <div className="clear"></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($tag: String) {
    allListJson(filter: { tags: { in: [$tag] } }) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
